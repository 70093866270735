@import '../../../../../../../theme/_colors.scss';

.status-engine {
  display: flex;
  gap: 15px;
  position: absolute;
  bottom: -15px;
  width: 100%;
  justify-content: flex-end;
  padding-right: 20px;
}