.custom-dialog {
  &__title {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  &__title-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0.5;
    cursor: pointer;
  }

  &__actions {
    justify-content: flex-start !important;
    padding: 1.5rem !important;
  }
}