// Include required parts of theme
//
@import '../../../../app/theme/colors';
// Adjust the `default/` path with theme folder that you used (e.g. dark/variables)

@import "default/variable-bs-overrides";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "default/variables";
@import "default/mixins";


/* put your own styles to customize and override the theme */
#user-dropdown {
  left: -75px;

  .dropdown-arrow {
    left: 6.2rem;
  }
}

#dropdown-toggle-datepicker {
  width: 150px;
}

#dropdown-datepicker {
  left: -8rem !important;
  top: 2.3rem !important;

  .dropdown-arrow {
    right: 1rem;
  }
}

.auth-header {
  background-color: #fb263b;
}

td {
  .form-group {
    margin-bottom: 0px;
  }
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 0rem !important;
}

.fc-day-number {
  float: right;
  padding: 2px;
  padding-right: 0.5rem;
}

// .page {
// 	min-height: 900px;
// }

.fc-scroller.fc-day-grid-container {
  overflow: hidden hidden !important;
}

.fc-event {
  cursor: pointer;
}

.table-hover tr {
  cursor: pointer;
}

.nav-menu {
  color: white !important;
  .menu-icon {
    color: white !important;
  }
}

.nav-menu:hover {
  color: grey !important;
  .menu-icon {
    color: grey !important;
  }
}

.tab-block {
  border: 2px solid grey;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 0 0 2% 0;
}

.modal-dialog {
  overflow-y: initial !important;
}
.modal-body {
  height: 50vh;
  overflow-y: auto;
}

/* Contract Tab */

.contract-tab-card-body {
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr;
  padding: 2%;
  margin: 0 0 2% 0;
  border: 4px solid grey;
}

.contract-tab-card-logo {
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 30%;
}

.contract-tab-card-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5%;
  font-size: 120%;
  height: auto;
  width: auto;
}

/* Telematics Tab */
.telematics-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template: 1fr 1fr 1fr;
  grid-gap: 10px;
  column-gap: 10px;
  font-size: 110%;
}

.telematics-infos-multiple-block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 3px;
}

/* StatusTab */
.status-tab-chart-container {
  width: 450px;
  margin: 20px auto;
}

// EquipmentDetail
.equipment-detail-imgCard {
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
  margin: 20px;
}

// DefaultLoading
.default-loading-container {
  width: 128px;
  margin: auto;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
}

// ErrorMessage
.error-message {
  text-align: center;
  font-weight: bold;
  margin: 50px auto;
}

.f4-map-flotte-modal-container {
  display: flex;
  height: 5px;
  width: 5px;
}

.f4-map-flotte-modal-left {
  height: 5px;
  width: 5px;
  background-color: red;
}

.f4-map-flotte-modal-left > img {
  max-height: 5px;
  max-width: 5px;
}

.f4-map-flotte-modal-right {
  background-color: red;
}

.linkToDetailsPage {
  cursor: pointer;
  color: $imperialRed;
  border: 1px solid $imperialRed;
  background-color: white;
  border-radius: 4px;
  margin-top: 5px;
}

.linkToDetailsPage:hover {
  filter: brightness(90%);
}

.linkToDetailsPageInfoIcon {
  display: inline-block;
  background-image: url("../../../../app/assets/images/Informations.svg");
  cursor: pointer;
  width: auto;
  height: auto;
  min-width: 20px;
  min-height: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.linkToDetailsPageInfoIcon:hover {
  filter: invert(1.5);
}

.linkToLocationInfoIcon {
  display: inline-block;
  background-image: url("../../../../app/assets/images/Location.svg");
  cursor: pointer;
  width: auto;
  height: auto;
  min-width: 20px;
  min-height: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.linkToLocationInfoIcon:hover {
  filter: invert(1.5);
}

.lastSyncBellow24h {
  position: relative;
  color: red;
  cursor: pointer;
}

.lastSyncBellow24hInfo {
  position: relative;
  color: red;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  font-size: 80%;
}

.arrow_icon-filter-array:hover {
  color: $imperialRed;
}
//F4 MAP STYLING
.f4map-infoWindow.withCloseButton {
  padding: 0px;
  border-radius: 0px;


}
.f4map .f4map-infoWindow.withCloseButton{
  padding: 0px;
}
.f4map .f4map-infoWindowContent{
overflow-y: none;
overflow-x: hidden;
}
.f4map-btnCloseInfoWindow{
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
}

.link-button {
  color: #FFF;
  width: 100%;
}

.link-button:hover {
  color: #FFF;
}
