.pdf-display {
  &__download-btn {
    padding: 0 !important;
    width: 15vw;
  }

  &__download-link {
    align-items: center;
    display: grid;
    color: white !important;
    width: 15vw;
    height: 2rem;
  }

  &__viewer {
    height: 35vw;
  }
}