.search-input {
    width: 100%;

    &__stack {
        max-height: 50px;
        width: 100%;

        .MuiFormControl-root {
            width: 100%;
        }
    }

    &__adornment {
        margin: 0 .5rem !important;
    }

    &__result {
        position: absolute;
        z-index: 11;
        min-width: 30vw;
        overflow: auto;
        max-height: 580px;

        &--desktop-width {
            width: 30vw;
        }

        &--mobile-width {
            width: 80vw;
        }
    }

    &__result-paper {
        padding: 16px;
        background-color: white;
    }

    &__text-pointer {
        cursor: pointer;
    }
}