@import "../../../theme/colors";

.legend-aside-box {
    background-color: $white;
    padding: 20px;
    gap: 20px;

    @media only screen and (max-width: 600px) {
        display: grid !important;
        grid-template-columns: repeat(2, auto);
    }

    &__element {
        align-items: center;
        gap: 15px;
        padding: 5px 5px 5px 0;
        width: max-content;
    }

    &__color-square {
        height: 25px;
        width: 25px;
        min-height: 25px;
        min-width: 25px;
    }

}