.filter {
  padding: 4px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: white;
    transform: scale(1.01);
  }

  &__icon {
    &--desktop-font-size {
      font-size: 40px !important;
    }
  }
}