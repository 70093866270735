$darkCandyAppleRed: #AD0100;
$imperialRed: #FB263D;
$paradisePink: #ED4E5C;
$strawberry: #FB263B;
$mauvelous: #F499A1;
$chineseBlack: #121010;
$charlestonGreen: #2D2A2C;
$dimGray: #6D686A;
$sonicSilver: #7D7779;
$white: #FFFFFF;
$ghostWhite: #FAF8FB;
$isabelline: #F0EDEE;
$platinum: #E2E2E2;
$lightGray: #D5D4D5;
$oceanBlue: #4A48B8;
$mediumElectricBlue: #005795;
$starCommandBlue: #007EB4;
$moonstone: #3398C3;
$darkSkyBlue: #80BFD9;
$oliveDrab: #7FA21B;
$darkLemonLime: #92BA1F;
$americanOrange: #FF8A00;
$quickSilver: #A4A5A5;
$fulvous: #DF7800;
$darkOrchid: #941ABD;
$mysticMaroon: #B8487B;
$zomp: #3BA484;
$raisinBlack: #262626;
$cyanCornflowerBlue: #2599C1;
$blueJeans: #5CB1FF;
$jasper: #E03C3C;
$ateneoBlue: #003C73;
$moonstoneBlue: #6AC4CA;
$cyanBlueAzure: #398DC5;
$darkCornflowerBlue: #2D3D81;
$brightGray: #E5F2F7;
$disconnectedGoobie: #f0ee37;

:export {
    darkCandyAppleRed: $darkCandyAppleRed;
    imperialRed: $imperialRed;
    paradisePink: $paradisePink;
    strawberry: $strawberry;
    mauvelous: $mauvelous;
    chineseBlack: $chineseBlack;
    charlestonGreen: $charlestonGreen;
    dimGray: $dimGray;
    sonicSilver: $sonicSilver;
    white: $white;
    ghostWhite: $ghostWhite;
    isabelline: $isabelline;
    platinum: $platinum;
    lightGray: $lightGray;
    oceanBlue: $oceanBlue;
    mediumElectricBlue: $mediumElectricBlue;
    starCommandBlue: $starCommandBlue;
    moonstone: $moonstone;
    darkSkyBlue: $darkSkyBlue;
    oliveDrab: $oliveDrab;
    darkLemonLime: $darkLemonLime;
    americanOrange: $americanOrange;
    quickSilver: $quickSilver;
    fulvous: $fulvous;
    darkOrchid: $darkOrchid;
    mysticMaroon: $mysticMaroon;
    zomp: $zomp;
    raisinBlack: $raisinBlack;
    cyanCornflowerBlue: $cyanCornflowerBlue;
    blueJeans: $blueJeans;
    jasper: $jasper;
    ateneoBlue: $ateneoBlue;
    moonstoneBlue: $moonstoneBlue;
    cyanBlueAzure: $cyanBlueAzure;
    darkCornflowerBlue: $darkCornflowerBlue;
    brightGray: $brightGray;
    disconnectedGoobie: $disconnectedGoobie;
}