body {
	padding: 0px !important;
	width: 100vw !important;
}

.metrics-equipment {
	.metric-row {
		.metric {
			min-height: 0rem;
		}
	}
}

.badge-light {
	background-color: #bbbb;
}

.dropdown #dropdown-toggle-datepicker {
	min-width: 175px !important;
}

.flat-label th div button {
	box-shadow: none
}

.center-text,
.text-center {
	display: flex;
	justify-content: center;
	align-items: center;
}