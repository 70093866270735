.calendar-mach-usage-graph {
    width: 100%;

    &__meteo-bar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-top: 10px;
    }

    &__meteo-bar-image {
        border-radius: 100%;
        position: relative;
        left: -20px;
        max-height: 50px;
    }

    &__meteo-bar-desc {
        position: relative;
        left: -10px;
    }

    &__usage-switch {
        justify-content: flex-end;
    }
}