.equipment-content-details {
  &__image {
    max-height: 100%;
    max-width: 100%;
    border-radius: 3px;
  }

  &__description {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    margin-top: 20px;
  }
}