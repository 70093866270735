@import '../../../../../theme/_colors.scss';

.machine-marker-popup {

  &__jobsite-info,
  &__agency-info {
    display: flex !important;
    align-items: center;
    gap: 5px;

    @media only screen and (max-width: 600px) {
      display: inline;
    }
  }

  &__links {
    display: flex;
    margin-left: auto;
    gap: .3rem;
  }


  &__copy-clip-board{
    cursor: pointer;
  }


  &__rent-info-date {
    cursor: initial;
  }

  &__rent-date-expired {
    cursor: initial;
    color: #FF8A00;
  }

  &__icons{
    height: 24px;
    width: 24px;
  }

  &__google-icon {
    color: #7D7779;
  
    &:hover {
      filter: invert(1.5);
    }
  }
}

