.calendar-day-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    cursor: pointer;
    transition: background-color 250ms linear;
    border: 2px solid transparent;

    &:hover {
        background-color: rgba(#007EB4, 0.2);
    }

    &--date-hover {
        border: 2px solid hsl(62, 100%, 50%);
    }
}