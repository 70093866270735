.stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
}

.title {
  display: flex;
  gap: 15px;
}

.length {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.select {
  width: 25%;
  @media only screen and (max-width: 600px) {
      width: 100%;
  }
}