.mui-custom-calendar {

    &__button {
        padding: 0 !important;
        margin: 0 !important;
        margin-top: 1rem !important;
        width: fit-content
    }

    &__month-details {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    &__month-utilisation {
        overflow: hidden;
        display: grid !important;
        grid-template-columns: 5% 90% 5%;
        grid-template-rows: 1fr;
    }

    &__month-list {
        white-space: nowrap;
        transition: transform 0.3s;
        display: grid !important;
        grid-template-rows: 1fr;
        width: auto;
    }

    &__keyboard-arrow-stack {
        z-index: 10;
        display: flex !important;
        justify-content: center;
        align-items: center;
        background-color: #fff;

        &--hidden {
            visibility: hidden;
        }
    }

    &__arrow-button {
        padding: 0 !important;
        margin: 0 !important;
    }

    &__keyboard-arrow {
        color: 'primary.main';
        border: 2px solid red;
    }
}