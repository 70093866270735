@import "../../app/theme/colors";

.percentage-content {
  &--up {
    color: $darkLemonLime;
  }

  &--down {
    color: $americanOrange;
  }

  &__icon {
    font-size: 1rem;
  }
}