.text-table {
  &__table {
    min-width: 400px;
    width: 100%;
  }

  &__cell {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &__show-details-btn {
    text-decoration: underline !important;
  }

  &__pointer {
    cursor: pointer;
  }

  &__empty-text {
    text-align: center;
  }
}