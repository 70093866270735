.machine-last-sync {
  word-wrap: break-word;
  max-width: 350px;

  &__error-icon {
    @media only screen and (max-width: 600px) {
      float: right;
      padding: 0 !important;
    }
  }
}