// !important flag used to override MUI styles
.cockpit {
  &-recence {
    &-buttons-box {
      margin: 30px 0 !important;

      &__button {
        background-color: #F0EDEE !important;
        border-radius: 0 !important;
        color: #121010 !important;
        margin-right: 10px !important;
        padding: 10px 20px !important;

        &:disabled {
          border: 1px solid;
          color: #007EB4 !important;
        }
      }
    }

    &__last-update {
      margin-left: 10px;
    }

    &__manufacturer-accordion {
      margin-top: 20px;
    }

    &__manufacturer-icon {
      color: #007EB4;
    }

  }
  &-goobie {
    &__informations {
      margin-top: 30px;
      padding: 30px;

      &-title {
        flex-grow: 2;
        margin-bottom: 30px !important;
      }
    }

    &__list {

      &-title {
        display: inline-block;
        margin-bottom: 20px;
        width: 150px;
      }
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }

  &-warning-text {
    color: red;
  }
}
