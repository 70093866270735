.plain-app-nav-bar {
    width: 100%;
    z-index: 50;

    &__search-bar {
        display: grid !important;
        grid-template-columns: 200px auto;
        justify-content: center;
        align-items: center;
        grid-template-rows: 56px;
        padding: 2px;

        &--grid-temp-col-none {
            grid-template-columns: none !important;
        }
    }

    &__loading-icon {
        color: white;
    }

    &__account-menu {
        .MuiMenu-paper {
            overflow: visible;
            filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
            margin-top: 12px;

            & .MuiAvatar-root {
                width: 32px;
                height: 32px;
                margin-left: -4px;
                margin-right: 8px;
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                right: 14px;
                width: 10px;
                height: 10px;
                transform: translateY(-50%) rotate(45deg);
                z-index: 0;
                background-color: white;
            }
        }
    }

    &__bottom-section {
        width: 100%;
        padding: 16px;
    }

    &__logo {
      margin-right: 20px;
    }

    &__link {
      color: #121010;
      margin-right: 12px;
      text-decoration: none;

      &:hover {
        color: #3398C3;
      }

      &:last-child {
        margin-right: 0;
      }

      &--active {
        color: #007EB4;
        font-weight: bold;
      }

      &--disabled {
        color:#E2E2E2;
        font-style: italic;
        margin-right: 12px !important; // Override MUI styles

        &:last-child {
          margin-right: 0;
        }
      }
    }
}
