.machine-carte {
  height: calc(100% - 129px);
  position: relative;

  &__card {
    height: calc(100vh - 230px);
    width: 100%;
    min-width: 500px !important;
  }

  &__switches-header {
    position: absolute;
    top: -50px;
    right: 0;

    &--mobile-view {
      position: relative;
      top: 0px;
      right: 0;

    }
  }
}

.marker-card{
  height: auto !important;
  width: 180px !important;
  padding: 20px 10px 10px 10px!important;
  background-color: #3398C3 !important;
  color: white !important;
  text-align: center;
}
