.card-table-details {
  &__table {
    min-width: 650px;
  }

  &__body-row {
    cursor: pointer;

    &:last-child td,
    &:last-child th {
      border: 0
    }
  }
}