.position-modifier {
  &__title {
    margin-bottom: 15px !important;
  }

  &__desc {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}