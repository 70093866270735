.lox-login-form {
  &__text-field {
    border-radius: 0px;
    font-size: 50px;
  }

  &__email {
    margin-bottom: 24px;
  }

  &__links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  &__login-btn {
    min-width: 120px;
  }
}