.jobsite-carte {
  height: calc(100% - 129px);
  position: relative;

  &__map {
    min-height: calc(100vh - 230px);
  }

  &__switches-header {
    position: absolute;
    top: -50px;
    right: 0;

    &--mobile-view {
      position: relative;
      top: 0px;
      right: 0;

    }
  }
}