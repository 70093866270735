@import "../../../theme/colors";

.equipment-list-and-map-container {
  height: 100%;
  position: relative;
}

.equipment-list-and-map-filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.equipment-list-and-map-filter {
  padding: 0px;
}

.equipment-list-and-map-filter-header {
  display: flex;
  align-items: center;
}

.equipment-list-and-map-filters-reset {
  color: $imperialRed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  &__btn {
    text-transform: capitalize;
    text-decoration: underline;
    cursor: pointer;
  }

  &__btn:hover {
    font-weight: bold;
  }
}

.equipment-list-and-map-box {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr;
  gap: 10px;

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 100%);
  }
}

.equipment-list-and-map-filter-chip {
  color: white !important;
  border-radius: 3px !important;

  .MuiChip-deleteIcon {
    color: white !important;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.equipment-list-and-map-filters-options-container {
  display: grid;
  justify-content: flex-end;
  grid-template-columns: 93% auto;
  grid-template-rows: 1fr;
  margin-top: 10px;
  margin-bottom: 10px;
}