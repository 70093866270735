.legend {
    display: flex;
    align-items: center;
    gap: 5px;

    &__stack {
        height: 20px;
        width: 40px;
    }

    &__color-square {
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
    }
}