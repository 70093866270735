.map-and-list-buttons {
  margin-left: 5px;
  flex-direction: row !important;

  &__btn {
    padding: 2px !important;
  }

  &__icon {
    font-size: 35px !important;
  }
}