.machine-group-create {
    &__pointer {
        cursor: pointer;
    }

    &__title {
        margin-top: 20px
    }

    &__info {
        margin-top: 20px;
        font-weight: bold;
    }

    &__stack {
        margin-top: 1rem;
        margin-bottom: 3rem;
    }

    &__text-field {
        width: 40%;
    }

    &__save-stack {
        margin-top: 30px
    }

    &__save-btn {
        font-weight: bold;
        text-transform: none;
        border-radius: 2px;
    }

    &__error {
        font-weight: bold;
    }
}