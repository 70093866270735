.button-positions-view {
    display: flex !important;
    flex-direction: row !important;
    align-self: start;
    margin: 1rem 0;
    gap: 20px;

    &__btn {
        max-height: 100%;
        max-width: 300px;
    }
}