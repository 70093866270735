.card-link {
  &__link {
    text-decoration: none !important;
  }

  &__box {
    cursor: default;
  }

  &__paper {

    height: 250px;
    width: 250px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @media only screen and (max-width: 600px) {
      height: auto;
      width: 88vw;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  &__icon {
    font-size: 50px !important;
  }

  &__title {
    @media only screen and (max-width: 600px) {
      margin-left: 1rem !important
    }
  }

  &__description {
    margin-bottom: 16px;
  }

  &__notifications-panel {
    @media only screen and (max-width: 600px) {
      margin-left: auto;
    }
  }

  &__notification-box {
    margin-right: 16px;
    display: inline-block;
  }

  &__notification-icon {
    font-size: 30px;
  }
}