.chart-bar-machine-usage {
    margin-top: 20px;

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__bar {
        height: 400px;
        position: relative;
        margin-bottom: 1%;
        padding: 1%;
    }
}