.machine-group-list {
  min-height: 40vh;

  &__actions {
    justify-content: flex-end;
  }

  &__btn {
    text-decoration: underline !important;
    text-transform: none !important;
    font-weight: 700 !important;
  }

  &__title {
    margin-bottom: 2rem !important;
  }

  &__create-btn {
    margin: 0px !important;
    align-self: end;
  }

  &__loading {
    margin-top: 3rem;
    position: relative;
  }
}