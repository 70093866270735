.mui-custom-calendar-legend {
    display: grid !important;
    width: 100%;
    align-self: center;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(100px, 150px));
    justify-content: space-evenly;
    margin-top: 5px;

    &__category {
        display: flex !important;
        flex-direction: column;
    }

    &__category-grid {
        display: grid !important;
        grid-template-columns: min-content max-content;
        gap: 5px;
        grid-template-rows: min-content ;
    }

    &__color {
        height: 10px;
        width: 15px;
        min-height: 15px;
        min-width: 30px;
    }

    /* &__description {} */

    &__time-info {
        display: flex !important;
        margin-left: 35px;
        align-items: center;
        font-weight: bold;
        font-size: 1.2vw;
    }

    &--mobile-view {
        display: grid !important;
        width: 45%;
        align-self: center;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        justify-content: space-evenly;
        margin-top: 5px;
        gap: 5px;
    }

     &__time-info--mobile-view {        
        font-size: 100%;
        margin-left: 0px
    }

}
