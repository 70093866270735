.global-machine-usage {
    width: 100%;

    &__content {
        display: flex;
        width: 40%;
        justify-self: center;
        align-self: center;

        @media only screen and (max-width: 600px) {
            width: 100%
        }
    }
}
