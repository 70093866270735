.table-with-pagination {

  .MuiTable-root {
    min-width: 0;
  }

  &__filters {
    display: grid !important;
    grid-template-columns: 93% auto;
    justify-content: flex-end;
    grid-template-rows: 1fr;
    margin-top: 10px;
    margin-bottom: 10px;

    @media only screen and (max-width: 600px) {
      display: block !important;
    }
  }

  &__filter-options {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media only screen and (max-width: 600px) {
      display: block !important;
    }
  }

  &__filters-actions {
    color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  &__filters-actions-reset-btn {
    text-transform: capitalize;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }

  &__chip-stack {
    padding: 0px;
  }

  &__chip-stack-name {
    display: flex;
    align-items: center;
  }

  &__chip-stack-chip {
    color: white !important;
    border-radius: 3px !important;

    .MuiChip-deleteIcon {
      color: white !important;
    }

    .MuiChip-deleteIcon:hover {
      transform: scale(1.1);
    }
  }

  &__simple-table {
    display: grid !important;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
  }

  &__simple-table-desc {
    @media only screen and (max-width: 600px) {
      grid-column: span 1/-1;
    }
  }

  &__simple-table-data-desc {
    grid-column: span 3/-1;
    display: flex;
    justify-content: right;
    align-items: flex-end;

    @media only screen and (max-width: 600px) {
      grid-column: span 1/-1;
    }
  }

  &__simple-table-desc-text {
    margin-bottom: 15px !important;
  }

  &__simple-table-data-desc-box {
    margin: 0 8px;
  }

  &__data_desc {
    display: grid !important;
    grid-template-columns: 10fr 0.5fr;
    grid-template-rows: 1fr;
  }

  &__table-body {
    height: 100%;
    gap: 0px !important;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    padding: 5px;
  }

  &__rows-per-page-options {
    margin-top: 10px;
  }
}