.specification-list {
  &__name {
    display: flex;
    align-items: center;
    margin-right: 5px !important;
  }

  &__value {
    display: flex;
    align-items: center;
    margin: 0px !important;
  }
}