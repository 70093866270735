.machine-filter-dialog {

    &__text {
        @media only screen and (max-width: 600px) {
            font-size: 1.5rem !important;
        }
    }

    &__title {
        position: relative;
    }

    &__title-text {
        font-weight: bold !important;
    }

    &__title-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 80vh;
    }

    &__reset-button {
        padding: 0 !important;
    }

    &__reset-text {
        text-transform: capitalize;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            font-weight: bold;
        }
    }

    &__machines-stack {
        gap: .5rem;
    }

    &__machines-states {
        display: grid !important;
        grid-template-columns: repeat(3, 15%);
        grid-template-rows: auto;
        gap: 10px;

        @media only screen and (max-width: 600px) {
            grid-template-columns: repeat(1, 50%);
        }
    }

    &__filters {
        gap: 20px;
    }

    &__filter-stack {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 1rem;
    }

    &__filter-select {
        width: 25%;

        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }

    &__actions {
        display: flex !important;
        justify-content: flex-start !important;
        padding-left: 25px !important;
        padding-bottom: 25px !important;
    }

    &__save-button {
        text-transform: capitalize !important;
    }
}