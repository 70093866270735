.report {
  &__title {
    margin: 8px !important;
    margin-bottom: 18px !important;
  }

  &__card {
    margin: 8px;
    min-height: 70vh;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
}