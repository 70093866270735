.calendar-month {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    width: 250px;
    min-width: 250px;

    @media only screen and (max-width: 600px) {
        min-width: 0;
        width: auto;
        font-size: 0.8rem;
    }

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        margin: 0;
        padding-bottom: 5px;
    }

    &__column {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    &__parent-grid {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(auto-fit, minmax(14%, 1fr));
    }

    &__span {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }

    &__grid {
        display: grid;
        grid-template-rows: repeat(auto, 1fr);
        grid-template-columns: repeat(7, 1fr);
    }
}