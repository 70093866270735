.tab-button {

  &--not-default {
    button {
      margin-right: 8px;
    }
  }

  &__link-tab {
    text-transform: none !important;
    border-radius: 4px !important;
  }

  &__tab-list {
    .MuiTabs-indicator {
      display: none;
    }
  }

  &__tab-panel {
    margin: 0;
    padding: 20px 0 0 0 !important;
  }

  &__select {
    width: 100%;
  }
}