.setting.members-edit-modal {
  &__title {
    margin: 0;
    padding: 8px;
  }

  &__icon {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}