.agency-geozone {
  &__title {
    margin-bottom: 15px !important;
  }

  &__desc {
    margin-bottom: 1rem !important;
  }

  &__region-btn {
    margin: 0;
    width: 100%;
  }

  &__depart-btn {
    margin-right: 8px;
  }
}