.carousel {
  display: flex;
  width: 100%;
  flex-direction: row;

  &__content {
    transition: all 0.5s ease;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__actions-btn {
    &--hidden {
      visibility: hidden;
    }
  }
}