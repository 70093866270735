.loxform {
  &__textfield {
    border-radius: 0;
    font-size: 50px;
  }

  &__select-form {
    min-width: 120px;
  }

  &__select-form-label {
    padding-left: 16px
  }

  &__select-form-select {
    padding-left: 9.6px
  }

  &__save-btn {
    width: 120px;
  }
}