.login {
  height: 100%;
  background: linear-gradient(180deg, rgba(228, 1, 21, 1) 0%, rgba(173, 1, 0, 1) 100%);

  &__stack {
    height: 100%;
  }

  &__paper {
    padding: 56px 40px 56px 40px;
    min-width: 400px;
  }

  &__app-name {
    text-align: center;
    margin-bottom: 40px;
  }
}