.security-alert-container {
  max-width: 200px;
}

.security-alert {
  min-width: 200px;
  width: auto;
  min-height: 200px;
  max-height: 200px;
  border-radius: 0px;
  display: grid;
  grid-template-rows: 1fr 2fr;
  align-items: center;
  padding: 0px 8px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 8px 8px 0px 8px;
  }
}
