@import '../../../../theme/_colors.scss';

.engine-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border-radius: 4px;
  gap: 5px;

  @media only screen and (max-width: 600px) {
    font-size: 0.7rem;
  }

  /* Machine Running State */

  &--NOTWORK {
    background-color: $imperialRed;
  }

  &--WORK {
    background-color: $darkLemonLime;
  }

  &--UNKNOWN, &--UNDEFINED {
    background: repeating-linear-gradient(135deg,
        #6D686A,
        #6D686A 2.5px,
        #7FA21B 2.5px,
        #7FA21B 5px);
  }

  /* Machine Status */ 


  &--INTRANSIT {
    background-color: $darkLemonLime;
  }

  //----------------
  &--PLANNEDRETURN {
      background-color: $fulvous;
  }

  &--RETURNSELLER {
      background-color: $darkOrchid;
  }

  &--SOLD {
      background-color: $mysticMaroon;
  }

  &--STOLEN {
      background-color: $zomp;
  }

  &--RETURNAREACTRLREQUIRED {
      background-color: $oceanBlue;
  }

  &--AVAILABLE {
      background-color: #00ff00;
  }

  &--INPROGRESSTRANSPORT {
      background-color: #000080;
  }
  &--SCRAPS {
      background-color: #2f4f4f;
  }
  &--RENTAL {
      background-color: #1e90ff;
  }
  &--RENTALSALE {
      background-color: #ff4500;
  }
  &--SHORTTERMBREAKDOWN {
      background-color: #dda0dd;
  }
   &--LONGTERMBREAKDOWN {
      background-color: #800000;
  }
  &--LOST {
      background-color: #d2b48c;
  }
  &--INTRANSIT {
    display: none;
  }

  //-----------------

  &__libelle {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}