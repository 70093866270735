.confirm-dialog {
  &__title {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  &__title-text {
    margin-right: 2rem !important;
  }

  &__title-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0.5;
    cursor: pointer;
  }

  &__actions {
    justify-content: space-between !important;
    padding: 1.5rem !important;
  }

  &__loading {
    margin-top: 2rem !important;
    transform: scale(0.5);
  }

  &__error {
    padding: 0 0 1rem 1.5rem !important;
  }
}