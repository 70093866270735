.home-page {
  &__title {
    margin: 8px !important;
  }

  &__links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  &__link {
    margin: 8px;
  }
}