.dialog-content {
  gap: 20px;

  &__stack {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__select-stack {
    padding-top: 10px;
  }

  &__select {
    width: 25%;
  }
}