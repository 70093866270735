.agency-information {
  padding: 32px;
  height: 100%;

  &__title {
    margin-bottom: 15px !important;
  }

  &__hours-limit {
    margin-bottom: 15px !important;
    font-weight: bold !important;
  }

  &__info {
    margin-bottom: 5px !important;
    font-weight: bold !important;
  }

  &__postal-code {
    margin-bottom: 15px !important;
  }

  &__schedule {
    margin-bottom: 15px !important;
    font-weight: bold !important;
  }

  &__week-day {
    font-weight: bold !important;
    white-space: pre;
  }
}