.machine-group-dialog {
    &__title {
        margin-top: 20px;
        font-weight: bold !important;
    }

    &__data-desc {
        margin-top: 10px
    }


    &__add-btn {
        margin-top: 10px;
        text-decoration: underline !important;
        text-transform: none !important;
        font-weight: 700 !important;
        max-width: 200px;
        padding-left: 0;
    }

    &__selected-text {
        margin-top: 2rem !important;
    }
}