.flotte-interaction {
  align-items: center;
  justify-content: flex-end;

  &--align-end {
    align-items: flex-end;
  }

  &__search-filter {
    align-items: center;
    justify-content: flex-end;
  }

  &__filter-options {
    position: absolute;
    top: 0;
    width: 15%;
    display: flex;
    justify-content: flex-end;
    padding: 5px;

    &--static-position {
      position: static;
      align-items: center;
    }
  }
}