.card-table {
  &__table {
    min-width: 650px;
    border-spacing: 0 8px;
    border-collapse: separate;
  }

  &__body-row {
    cursor: pointer;

    &:last-child td,
    &:last-child th {
      border: 0
    }
  }
}