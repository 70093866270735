
@import "../../../theme/colors";

.equipment-detail {    
    &__no-data {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $imperialRed;
        margin-bottom: 3.5rem;
    }

    &__title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    &__carte {
        padding: 0;
        height: 500px;
    }

    &__map {
        min-height: calc(100vh - 155px);
    }
}
