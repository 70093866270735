.table-header {
  &__cell {
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
  }

  &__cell-name {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
  }

  &__cell-sort {
    display: grid;
    grid-template-columns: 15px;
    grid-template-rows: 15px 15px;
  }

  &__popover-stack {
    padding: 8px;
  }
}