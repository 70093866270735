@import "../../../../theme/colors";

.marker-popup {
    margin: 0;
    position: relative;
    display: grid;
    min-width: max-content;
    height: fit-content;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 20;

    @media only screen and (max-width: 600px) {
        display: inline;
        font-size: 0.9rem;
    }

    &__header {
        background-color: $charlestonGreen;
        color: $white;
        display: grid;
        grid-template-columns: auto 2fr;
        grid-template-rows: 1fr;
        align-items: center;
        height: fit-content;
        position: relative;
        min-height: 70px;
    }

    &__header-title {
        cursor: pointer;
        display: flex;
        justify-content: center;
        justify-self: center;
        align-items: center;
        padding: 0px 10px 0px 0px;
        width: fit-content;

        @media only screen and (max-width: 600px) {
            font-size: 0.9rem;
        }
    }

    &__header-image {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 100px;
        max-width: 150px;
        min-width: 100px;
        margin: 10px;
    }

    &__header-image-size {
        height: auto;
        width: auto;
        max-height: 100px;
        max-width: 150px;
    }


    &__information-wrapper,
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        padding: 25px;
        background-color: $white;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    &__text-infos {
        display: flex;
        gap: 5px;

        &--alert {
            cursor: initial;
            color: $americanOrange;
            width: max-content;
        }
    }

    &__location {
        cursor: initial;
    }
}