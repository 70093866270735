.machine-location {
  width: 35px;
  display: grid !important;
  grid-template-columns: 110px 40px;
  grid-template-rows: 1fr;
  align-items: center;
  width: auto;

  @media only screen and (max-width: 600px) {
    display: flex !important;
    flex-direction: row !important;
    width: 100%;
  }

  &__location-text {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__tooltip {
    max-height: 40px;
    max-width: 40px;
    justify-self: flex-start;

    @media only screen and (max-width: 600px) {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: auto !important;
    }
  }
}